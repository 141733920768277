<script>
import i18n from "../i18n";
import SearchBar from "./SearchBar.vue";

export default {
  data() {
    return {
      languages: [{
        flag: require("@/assets/images/flags/us.svg"),
        language: "en",
        title: "English",
      },
      {
        flag: require("@/assets/images/flags/ua.svg"),
        language: "ua",
        title: "Українська",
      }
      ],
      lan: i18n.locale,
      text: null,
      flag: null,
      value: null,
      myVar: 1,
    };
  },
  components: {
    SearchBar
  },
  mounted() {
    document.addEventListener("scroll", function () {
      var pageTopbar = document.getElementById("page-topbar");
      if (pageTopbar) {
        document.body.scrollTop >= 50 || document.documentElement.scrollTop >= 50 ? pageTopbar.classList.add("topbar-shadow") : pageTopbar.classList.remove("topbar-shadow");
      }
    });
    if (document.getElementById("topnav-hamburger-icon"))
      document
        .getElementById("topnav-hamburger-icon")
        .addEventListener("click", this.toggleHamburgerMenu);
  },
  methods: {
    toggleHamburgerMenu() {
      var windowSize = document.documentElement.clientWidth;

      if (windowSize > 767)
        document.querySelector(".hamburger-icon").classList.toggle("open");

      //For collapse horizontal menu
      if (document.documentElement.getAttribute("data-layout") === "horizontal") {
        document.body.classList.contains("menu") ?
          document.body.classList.remove("menu") :
          document.body.classList.add("menu");
      }

    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    setLanguage(locale, country, flag) {
      this.lan = locale;
      this.text = country;
      this.flag = flag;
      document.getElementById("header-lang-img").setAttribute("src", flag);
      i18n.global.locale = locale;
      this.$forceUpdate();
    },
    hasProfileAvatar: function() {
      return localStorage.getItem('user.urlAvatar') && localStorage.getItem('user.urlAvatar') !== '' && localStorage.getItem('user.urlAvatar') !== 'null';
    },
  },
  computed: {
    getCurrentLanguage: function() {
      var lng;
      if(i18n.global.locale === 'ua')
        lng = "ua";
      else
        lng = "us";
      return require("@/assets/images/flags/" + lng + ".svg");
    },
    getProfileId: function() {
      return localStorage.getItem('user.id');
    },
    getProfileLogin: function() {
      return localStorage.getItem('user.login');
    },
    getProfileDisplayName: function() {
      return localStorage.getItem('user.displayName');
    },    
    getProfileAvatar: function() {
      return localStorage.getItem('user.urlAvatar');
    },
    getBalanceTotal: function() {
      return localStorage.getItem('balance.amount');
    },
    visibleSearchBar() {
      var name = this.$route.name;
      if(name === 'search')
        return false;
      return true;
    }
  },
};
</script>

<template>
  <header id="page-topbar">
    <div class="layout-width">
      <div class="navbar-header">
        <div class="d-flex">

          <div class="navbar-brand-box horizontal-logo">
            <router-link to="/" class="logo logo-dark">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-dark.png" alt="" height="45" />
              </span>
            </router-link>

            <router-link to="/" class="logo logo-light">
              <span class="logo-sm">
                <img src="@/assets/images/logo-sm.png" alt="" height="22" />
              </span>
              <span class="logo-lg">
                <img src="@/assets/images/logo-light.png" alt="" height="45" />
              </span>
            </router-link>
          </div>

          <button type="button" class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
            <span class="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </span>
          </button>

          <SearchBar v-if="visibleSearchBar"></SearchBar>

        </div>

        <div class="d-flex align-items-center">

          <div class="dropdown ms-1 topbar-head-dropdown header-item">
            <button type="button" class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img id="header-lang-img" :src="getCurrentLanguage" alt="Header Language" height="20" class="rounded" />
            </button>
            <div class="dropdown-menu dropdown-menu-end">
              <a href="javascript:void(0);" v-for="(entry, i) in languages" :key="`Lang${i}`" :value="entry"
                @click="setLanguage(entry.language, entry.title, entry.flag)"
                :class="{ active: lan === entry.language }" class="dropdown-item notify-item language py-2"
                data-lang="en" title="English">
                  <img :src="entry.flag" alt="user-image" class="me-2 rounded" height="18" />
                  <span class="align-middle">{{ entry.title }}</span>
              </a>
            </div>
          </div>

          <div class="ms-sm-3 topbar-head" v-if="getProfileId">
            <router-link to="/account/payments">
              <span><b class="text-muted">₴</b> {{ getBalanceTotal }}</span>
            </router-link>
          </div>

          <div class="dropdown ms-sm-3 header-item topbar-user" v-if="!getProfileId">
            <router-link class="ms-4 me-4" to="/login">
              {{ $t('t-header-profile-signin') }}
            </router-link>
          </div>

          <div class="dropdown ms-sm-3 header-item topbar-user" v-if="getProfileId">
            <button type="button" class="btn" id="page-header-user-dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="d-flex align-items-center">
                <img class="rounded-circle header-profile-user" :src="getProfileAvatar" :alt="getProfileDisplayName" v-if="hasProfileAvatar()" />
                <div class="avatar-xs flex-shrink-0" v-if="!hasProfileAvatar()">
                  <div class="avatar-title rounded-circle">{{ getProfileLogin[0].toUpperCase() }}</div>
                </div>
                <span class="text-start ms-xl-2">
                  <span class=" d-none d-xl-inline-block ms-1 fw-medium user-name-text">{{ getProfileLogin }}</span>
                  <span class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{{ getProfileDisplayName }}</span>
                </span>
              </span>
            </button>

            <div class="dropdown-menu dropdown-menu-end">
              <h6 class="dropdown-header">{{ $t('t-header-profile-title') }} {{ getProfileDisplayName }}</h6>
              <router-link class="dropdown-item" to="/account/dashboard">
                <i class="ri-home-7-line text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{ $t('t-header-profile-dashboard') }}</span>
              </router-link>
              <router-link class="dropdown-item" to="/account/favourites">
                <i class="ri-bookmark-line text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{ $t('t-header-profile-favourites') }}</span>
              </router-link>
              <router-link class="dropdown-item" to="/account/invites">
                <i class="ri-user-voice-line text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{ $t('t-header-profile-invites') }}</span>
              </router-link>
              <router-link class="dropdown-item" to="/account">
                <i class="ri-settings-3-line text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle">{{ $t('t-header-profile-account') }}</span>
              </router-link>
              <div class="dropdown-divider"></div>
              <router-link class="dropdown-item" to="/auth/signout">
                <i class="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                <span class="align-middle" data-key="t-logout">{{ $t('t-header-profile-signout') }}</span>
              </router-link>
            </div>

          </div>

        </div>
      </div>
    </div>
  </header>
</template>